import { useContext } from "react";
import React from "react";
import { logEvent } from "@src/utils/logClient";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import { PlayIcon } from "../schoolleader/styles";
import styled from "@emotion/styled";

interface VideSectionProps {
  youtubeID: string;
}
const VideBox = styled.a`
  overflow: hidden;
  border-radius: 18px;
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  div {
    // position: absolute;
    // top: 50%;
    // left: 0;
    // right: 0;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50px;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    // z-index: 10;
    width: 25px;
    height: 25px;
    transform: translate(-50%, -50%);
  }
  img {
    display: block;
  }
`;
export const VideoSection = ({ youtubeID }: VideSectionProps) => {
  const modalContext = useContext(ModalContext);

  const openVideoModal = () => {
    logEvent({
      eventName: "web.external.iste-2024.vide-play",
      eventValue: window.location.href,
    });
    modalContext.showModal(ModalType.VideoModal, { youtubeID });
  };

  return (
    <VideBox onClick={openVideoModal}>
      <div>
        <PlayIcon />
      </div>
    </VideBox>
  );
};
