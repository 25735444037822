import styled from "@emotion/styled";
import { Body2 } from "@src/styles/piltri";
import React from "react";

const GradientTextStyle = styled(Body2)`
  background: linear-gradient(270deg, #0098ff 0%, #9774ff 33%, #e963be 66%, #f9636a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  display: inline-block;
`;

const GradientText = ({ children, wrapperCss = null, textCss = null }) => (
  <div css={wrapperCss}>
    <GradientTextStyle css={textCss}>{children}</GradientTextStyle>
  </div>
);

export default GradientText;
