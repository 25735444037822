// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPlus = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3225 11.0475H13.3276C13.1213 11.0475 12.9526 10.8788 12.9526 10.6725V6.05252C12.9526 5.56127 12.6001 5.11502 12.1126 5.06627C11.5501 5.01002 11.0776 5.44877 11.0776 6.00002V10.6725C11.0776 10.8788 10.9088 11.0475 10.7026 11.0475H5.6813C5.19005 11.0475 4.7438 11.4 4.69505 11.8875C4.6388 12.45 5.07755 12.9225 5.6288 12.9225H10.7063C10.9126 12.9225 11.0813 13.0913 11.0813 13.2975V17.9475C11.0813 18.4388 11.4338 18.885 11.9213 18.9338C12.4838 18.99 12.9563 18.5513 12.9563 18V13.2975C12.9563 13.0913 13.1251 12.9225 13.3313 12.9225H18.375C18.9263 12.9225 19.365 12.45 19.3088 11.8875C19.26 11.4 18.8138 11.0475 18.3225 11.0475Z" />
    </svg>
  );
};
