export type TranslationReturn = ReturnType<typeof useTranslations>;

import { TranslationContext } from "@src/components/translation/TranslationContext";
import { forEach, isObject } from "lodash";
import { useContext } from "react";
import data from "@src/assets/language/en/website2.website.json";

export const useTranslations = () => {
  const t = useContext(TranslationContext);

  const values = {
    ...data.directus.become_mentor_v2,
  };

  forEach(values, (_v, k) => {
    if (isObject(values[k])) {
      forEach(values[k], (_v, k2) => {
        const translation = t.translate(`directus.become_mentor_v2.${k}.${k2}`);
        if (!values[k]) values[k] = {};
        if (translation) values[k][k2] = translation;
      });
      return;
    }

    const translation = t.translate(`directus.become_mentor_v2.${k}`);
    if (translation) values[k] = translation;
  });

  return values;
};
