// Perform a navigation to a URL
export const navigateTo = function (url: string) {
  // for testing navigation in Cypress
  if (window.Cypress && window.Cypress.navigateTo) {
    return window.Cypress.navigateTo(url);
  }
  window.location.assign(url);
};

export const origin = function () {
  return window.location.origin
    ? window.location.origin
    : `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
      }`;
};
