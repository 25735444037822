import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { Theme } from "@emotion/react";

type ButtonSize = "small" | "medium" | "large";
type Variant = "primary" | "secondary" | "tertiary" | "orange" | "subtle";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string;
  to?: string;
  size?: ButtonSize;
  variant?: Variant;
  target?: string;
}

const VARIANTS = (theme: Theme) => ({
  primary: {
    color: "#fff",
    background: theme.__new.colors.contentAccent,
    "&:hover": {
      background: theme.__new.colors.grape70,
    },
    "&:active": {
      background: theme.__new.colors.grape80,
    },
  },
  secondary: {
    color: "#fff",
    background: "#000",
    "&:hover": {
      background: "#454545",
    },
    "&:active": {
      background: "#454545",
    },
  },
  subtle: {
    color: theme.__new.colors.contentAccent,
    background: "#F6EEFF",
  },
  orange: {
    color: "#fff",
    background: "#FB9246",
    "&:hover": {
      background: "#FFA76B",
    },
    "&:active": {
      background: "#FFB88A",
    },
  },
  // tertiary variant commented out in original code...
});

const SIZES = (theme: Theme) => ({
  small: {
    fontSize: 13,
    // padding: '5px 10px',
  },
  medium: {
    fontSize: 16,
    height: 48,
  },
  large: {
    fontSize: 18,
    height: 60,
  },
});

const StyledButton = styled.button<ButtonProps>`
  padding-inline: ${(props) => (props.size === "small" ? "18px" : "24px")};
  height: ${(props) => (props.size === "small" ? "32px" : "48px")};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.__new.spacing[6]};
  border-radius: 100px;
  font-family: "HafferXH";
  font-size: ${(props) => (props.size === "small" ? "13px" : "16px")};
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  cursor: pointer;
  border: none;

  ${({ theme, variant }) => VARIANTS(theme)[variant || "primary"]}
  ${({ theme, size }) => SIZES(theme)[size || "medium"]}
`;

const Button: React.FC<ButtonProps> = ({ href, to, size = "medium", target, ...props }) => {
  if (href) {
    return <StyledButton as="a" href={href} size={size} target={target} {...props} />;
  }

  if (to) {
    return <StyledButton as={Link} to={to} size={size} {...props} />;
  }

  return <StyledButton size={size} {...props} />;
};

export default Button;
