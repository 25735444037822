import React, { ReactNode, useEffect, useRef, useState } from "react";
import { IconVolumnOn, IconVolumnOff } from "@src/dds-icons";
import isMobile from "@src/utils/isMobile";
import styled from "@emotion/styled";
import HtmlVideo from "../dojo-islands/HtmlVideo";
import { mediaQueriesMax } from "@src/styles/theme";

type VimeoVideoProps = {
  quote: ReactNode;
  src: string;
  subtitle: any;
  isActive?: boolean;
  h?: string;
};

export default function Video({ src, quote, subtitle, isActive, h }: VimeoVideoProps) {
  const [active, setActive] = useState(false);

  // Use the isMobile function to determine if the user is on a mobile device
  const mobileInfo = isMobile();
  const isMobileDevice = mobileInfo.any; // Adjust based on your needs

  // Keep a reference to the player instance
  const playerRef = useRef<HTMLVideoElement | null>(null);

  const activateVideo = () => {
    if (!playerRef.current) return;
    setActive(true);
    playerRef.current.play().catch((error) => {
      console.error("Error playing the video:", error);
    });
  };

  const deactivateVideo = () => {
    if (!playerRef.current) return;
    setActive(false);
    playerRef.current.pause();
  };

  useEffect(() => {
    if (isActive !== false) return;
    setActive(false);
    deactivateVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <Wrapper
      active={active}
      onMouseEnter={isMobileDevice ? undefined : activateVideo}
      onMouseLeave={isMobileDevice ? undefined : deactivateVideo}
    >
      <HtmlVideo src={src} ref={playerRef} width="100%" playsInline autoPlay loop />
      <Overlay />
      <QuoteText>
        {quote}
        <span>{subtitle}</span>
      </QuoteText>

      <TapToPlayText>{active ? "Pause" : "Play"}</TapToPlayText>
      <IconWrapper active={active}>{active ? <IconVolumnOn size="s" /> : <IconVolumnOff size="s" />}</IconWrapper>
      <FullSizeButton type="button" onClick={isMobileDevice ? (active ? deactivateVideo : activateVideo) : undefined} />
    </Wrapper>
  );
}

// Styled Components (same as before, with minor adjustments)

const QuoteText = styled.div`
  position: absolute;
  bottom: 36px;
  left: 36px;
  right: 36px;
  font: 500 15px / 20px "Dojo Text", sans-serif;
  margin: 0;
  line-height: 1.2rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
`;

const TapToPlayText = styled.h1`
  display: none;
  opacity: 0.65;
  transition: opacity 250ms ease-out;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 36px;
  font: 500 15px / 20px "Dojo Text", sans-serif;
  margin: 0;
  line-height: 1.2rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
`;

const IconWrapper = styled.div<{ active: boolean }>`
  position: absolute;
  color: rgb(255, 255, 255);
  right: 36px;
  bottom: 36px;
  opacity: ${(props) => (props.active ? "1" : "0.65")};
`;

const Wrapper = styled.div<{ active: boolean }>`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  transition: transform 150ms ease-out;
  width: 100%;
  height: 100%;
  ${QuoteText} span p {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
  }
  &:hover ${QuoteText} span p {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  }

  ${mediaQueriesMax[0]} {
    ${QuoteText} span p {
      max-height: 500px;
      transition: none;
    }

    &:hover ${QuoteText} span p {
      max-height: 500px;
      transition: none;
    }
  }

  @media screen and (max-width: 1048px) {
    &:hover {
      transform: none;
    }

    ${QuoteText} span {
      opacity: 1;
    }
    ${TapToPlayText} {
      display: block;
    }

    &:hover ${IconWrapper} {
      opacity: ${(props) => (props.active ? "1" : "0.5")};
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(0deg, rgba(26, 25, 45, 0.5) 0%, rgba(26, 25, 45, 0) 100%);
  border-radius: 24px;
  pointer-events: none;
`;

const FullSizeButton = styled.button`
  position: absolute;
  inset: 0;
  border: none;
  background: transparent;
`;
